//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;

$green:#389f41;
$orange:#db4613;
$teal:#1fbcad;
$red:#e9402b;
$pink:#df3d73;
$yellow:#e7cb3b;
$blue:#1e72da;

$primary-color: $red;
$gray: #acacac;

//Fonts
$primary-font: 'interstate', sans-serif;
