@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    text-align: center;
    background-image: url('../img/bg.jpg');
    background-size: cover;
    color: $gray;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}

#header{
    .container{
        max-width: rem(820);
        @media screen and (max-width: 500px){
            padding: 0 rem(40);
            p:first-child{
                max-width: rem(245);
            }
        }
    }

    h1 img{
        display: block;
    }
    
    p{
        font-size: rem(22);
        
        @media screen and (max-width: 800px){
            font-size: rem(18);
        }
        color: $red;
        text-transform: uppercase;
        font-weight: bold;
        margin: 1em auto;
        &:nth-child(3){
            margin-top: 0;
            @media screen and (max-width: 500px){
                font-size: rem(17);
            }
        }

        @media screen and (min-width: 500px){
            br.mobile{
                display: none;
            }
        }
    }
}

#main{
    .container{
        max-width: rem(950); 
    }

    .listenbtn{
        display: inline-block;
        width: rem(420);
        height: rem(36);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        vertical-align: middle;
        font-weight: bold;
        margin: rem(10) auto;
        border: 1px solid $blue;
        color: $blue;
        text-transform: uppercase;
        font-size: rem(16);
        @media screen and (max-width: 500px){
            width: 100%;
        }
        @include hover{
            background-color: $blue;
            color: $white;
        }
    }

    .enter{
        display: inline-block;
        width: rem(200);
        height: rem(36);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        vertical-align: middle;
        font-weight: bold;
        margin: rem(10) auto;
        border: 1px solid $red;
        color: $red;
        text-transform: uppercase;
        font-size: rem(16);
        
        @media screen and (max-width: 500px){
            width: 100%;
        }

        @include hover{
            background-color: $red;
            color: $white;
        }
    }

    .listen{
        .btn{
            display: inline-block;
            width: rem(200);
            height: rem(36);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $white;
            vertical-align: middle;
            font-weight: bold;
            margin: rem(10) rem(7);
            border: 1px solid $red;
            color: $red;
            text-transform: uppercase;
            font-size: rem(16);
            @media screen and (max-width: 500px){
                width: 100%;
                margin-left: 0;
            }
    
            @include hover{
                background-color: $red;
                color: $white;
            }

            // &:nth-child(1){
            //     color: $green;
            //     border-color: $green;
            //     @include hover{
            //         background-color: $green;
            //         color: $white;
            //     }
            // }
            // &:nth-child(2){
            //     color: $pink;
            //     border-color: $pink;
            //     @include hover{
            //         background-color: $pink;
            //         color: $white;
            //     } 
            // }
            &:nth-child(1){
                color: $yellow;
                border-color: $yellow;
                @include hover{
                    background-color: $yellow;
                    color: $white;
                }
            }
            &:nth-child(2){
                color: $teal;
                border-color: $teal;
                @include hover{
                    background-color: $teal;
                    color: $white;
                }
            }
        }
    }
}
.dropdown{
    
    line-height: 0;
    @media screen and (max-width: 900px){
        br{
            display: none;
        }
    }
    a{
        display: inline-block;
        width: rem(200);
        height: rem(36);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        vertical-align: middle;
        font-weight: bold;
        margin: rem(9);

        i{
            display: inline-block;
            transform-origin: center;
            margin: 0 rem(4);
            font-size: rem(12);
            transition: transform .3s;
        }
        img{
            height: rem(16);
            width: auto;
            margin-left: rem(5);
            transition: height .3s;
        }
        span{
            padding-top: rem(2);
            display: block;
            font-size: rem(13);
            text-transform: uppercase;
        }
        &:nth-child(2){
            background-color: $green;
            border: 1px solid $green;
            img{
                height: rem(22);
            }
        }
        &:nth-child(3){
            background-color: $orange;
            border: 1px solid $orange;
            img{
                padding-bottom: rem(3);
            }
        }
        &:nth-child(4){
            background-color: $teal;
            border: 1px solid $teal;
            img{
                height: rem(12);
            }
        }
        &:nth-child(6){
            background-color: $red;
            border: 1px solid $red;
            
            img{
                height: rem(12);
            }
        }
        &:nth-child(7){
            background-color: $pink;
            border: 1px solid $pink;
            img{
                padding-top: rem(7);
                height: rem(21);
                width: rem(46);
            }
        }
        &:nth-child(8){
            background-color: $yellow;
            border: 1px solid $yellow;
            
            img{
                height: rem(18);
                
                width: rem(48);
                padding-bottom: rem(4);
            }
        }
        &:nth-child(9){
            background-color: $blue;
            border: 1px solid $blue;
        }

        &:nth-child(n){
            @include hover{
                background-color: transparent;
            }
        }
    }

    .toggle{
        display: flex;
        margin: rem(10) auto;
        border: 1px solid $blue;
        color: $blue;
        text-transform: uppercase;
        font-size: rem(16);
        
        @include hover{
            background-color: $blue !important;
            color: $white;
        }
    }

    p{
        font-size:.625rem;text-transform:uppercase;letter-spacing:.05em;
    }
    &.closed {
        a:not(.toggle){
            height: 0;
            opacity: 0;
            overflow: hidden;
            margin: 0 rem(9);
            font-size: 0;
            span{
                height: 0;
            }
            img{
                height: 0;
            }
        }

        p{font-size:0;}
    }

    &.closed i{
        transform: scale(1,-1);
    }
}

#footer{
    display: flex;
    .heads-left, .heads-right{
        flex: 1;
        background-repeat: no-repeat;
        
        background-size: auto rem(80);
    }

    .heads-left{
        background-image: url('../img/heads-left.png');
        background-position: bottom right;
    }

    .heads-right{
        background-image: url('../img/heads-right.png');
        background-position: bottom left;
    }

    @media screen and (max-width: 850px){
        display: block;

        .heads-left{
            background-image: none;
        }

        .heads-right{
            background-image: url('../img/heads-mobile.png');
            background-position: bottom center;
            background-size: 95% auto;
            height: rem(80);
            width: 100%;
            margin: 0 auto;
            max-width: rem(375);
        }
    }

    .container{
        flex-basis: rem(600);
        max-width: rem(600);
    }
    .newsletter{

        margin: rem(10);
        
        @media screen and (max-width: 500px){
            margin: rem(16) auto;
        }
        display: inline-block;
        vertical-align: middle;
        font-size: rem(12);
        color: $teal;
        input{
            width: rem(155);
            border-bottom: rem(2) solid $teal;
            text-transform: uppercase;
            color: $teal;
            text-align: center;
            padding: rem(3);
            &::placeholder{
                color: $teal;
                
            }
        }

        button{
            color: $teal;
            border: 1px solid $teal;
            font-weight: bold;
            padding: rem(4) rem(7);
            @include hover{
                color: $white;
                background-color: $teal;
            }
        }
    }
    .social{
        margin: rem(10);
        display: inline-block;
        vertical-align: middle;
        
        @media screen and (max-width: 500px){
            font-size: rem(20);
        }
        a{
            @include hover{
                color: $white;
            }
        }
        li{
            display: inline-block;
            &+li{
                margin-left: .8em;
            }
            &:nth-child(1){
                color:$pink;
            }
            &:nth-child(2){
                color:$green;
            }
            &:nth-child(3){
                color:$red;
            }
            &:nth-child(4){
                color:$yellow;
            }
            &:nth-child(5){
                color:$orange;
            }
            &:nth-child(6){
                color:$blue;
            }
            &:nth-child(7){
                color:$teal;
            }
        }
    }

    .copyright{
        font-size: rem(8);
        letter-spacing: .1em;
        text-transform: uppercase;

        svg{
            display: block;
            margin: 0 auto 0.3125rem;
            text-align: center;
        }
    }
}

.page-wrap{
    min-height: 100vh;
    @media screen and (max-width:500px){
        padding-top: rem(20);
    }
    display: flex;
    flex-direction: column;
    #main{
        flex: 1;
    }

    &::before{
        content: '';
        flex: 1;
    }
}


.popup{
    color: $primary-color;
    background-color: $black;
    background-size: cover;
    margin: rem(20) auto;
    padding: rem(30);
    width: 100%;
    max-width: rem(600);
    text-align: center;
    position: relative;
    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;
    .mfp-close{
        color: $primary-color;
    }

    #apple_music_form{
        margin-left: rem(15);
        display: inline-block;
        vertical-align: middle;
        font-size: rem(15);
        letter-spacing: .05em;
        @media screen and (max-width: 520px){
            margin: rem(25) auto 0;
            display: block;
        }
        input{
            border-bottom: rem(2) solid $primary-color;
            text-align: center;
            text-transform: uppercase;
            color: $primary-color;
            transition: .3s all;
            padding-top: rem(2);
            &::placeholder{
                color: $primary-color;
                line-height: 1;
            }
        }

        .btn{
            border: rem(2) solid $primary-color;
            color: $primary-color;
            @include hover{
                color: $white;
                background-color: $primary-color;
            }
        }
    }
}